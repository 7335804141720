






import { Route, RawLocation }         from 'vue-router'
import { Component, Vue, Prop }       from 'vue-property-decorator'
import { projectModule, stateModule } from '@/store'

import ProposalDetail from '@/components/proposal/detail/Index.vue'

import { ProcessResource }  from '@/models/process/ProcessResource'
import { ProjectResource }  from '@/models/projects/ProjectResource'
import { ProposalResource } from '@/models/proposals/ProposalResource'

@Component({
  components: {
    ProposalDetail
  }
})
export default class DocumentProposalDetail extends Vue {

  @Prop()
  private process!: ProcessResource

  private proposal: ProposalResource | null = null

  private get project(): ProjectResource | null {
    return projectModule.detail
  }

  // Route hooks
  private async created(): Promise<void> {
    stateModule.setLoading(true)
    try {
      const { data } = await this.process.getProposal(parseInt(this.$route.params.proposal_id, 10))
      this.proposal = data
    } finally {
      stateModule.setLoading(false)
    }
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: any): Promise<void> {
    if (parseInt(to.params.childId, 10) !== (this.proposal ? this.proposal.id : 0)) {
      stateModule.setLoading(true)
      try {
        const { data } = await this.process.getProposal(parseInt(to.params.proposal_id, 10))
        this.proposal = data
        next()
      } catch ({ message }) {
        next(from.path)
      } finally {
        stateModule.setLoading(false)
      }
    } else {
      next()
    }
  }

  private get backRoute(): RawLocation {
    const params = { ...this.$route.params }
    delete params.proposal_id
    if (this.$route.name === 'projects-detail-document-proposal-detail') {
      return { name: 'projects-detail-document-proposals', params }
    }
    return { name: 'projects-detail-document', params }
  }

}
